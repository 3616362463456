<template>
  <div class="space-y-6">
    <asom-alert
      class="col-span-2"
      v-if="shiftInfoError"
      variant="error"
      :error-message="shiftInfoError"
    />
    <asom-alert
      class="col-span-2"
      v-if="submissionError"
      variant="error"
      :error-message="submissionError"
    />
    <div class="w-full text-center" v-if="isLoadingShiftInfo">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <template v-else-if="!shiftInfoError">
      <shift-info
        :rosterDWS="rosterDWS"
        :stationName="rosterStationName"
        :startTime="formData.handoverBase.startTime"
        :endTime="formData.handoverBase.endTime"
      />
      <div class="space-y-4">
        <h3 class="subheader">Cash Management</h3>
        <psm-summary
          :readonly="false"
          :loading="formData.psmSummary.isLoading"
          :data="formData.psmSummary.data"
          :error="formData.psmSummary.error"
          v-model:isAligned="formData.psmSummary.isAligned"
          v-model:discrepancyReports="formData.psmSummary.discrepancyReports"
        />
        <psc-cash-declaration-summary
          :readonly="false"
          :loading="formData.pscCashDeclarationSummary.isLoading"
          :data="formData.pscCashDeclarationSummary.data"
          :error="formData.pscCashDeclarationSummary.error"
          v-model:isAligned="formData.pscCashDeclarationSummary.isAligned"
          v-model:discrepancyReports="formData.pscCashDeclarationSummary.discrepancyReports"
        />
        <psc-manual-change-summary
          :readonly="false"
          :loading="formData.pscManualChangeSummary.isLoading"
          :data="formData.pscManualChangeSummary.data"
          :error="formData.pscManualChangeSummary.error"
          v-model:isAligned="formData.pscManualChangeSummary.isAligned"
          v-model:discrepancyReports="
            formData.pscManualChangeSummary.discrepancyReports
          "
        />
        <pre-encoded-ticket-summary
          :readonly="false"
          :loading="formData.preEncodedTicketSummary.isLoading"
          :data="formData.preEncodedTicketSummary.data"
          :error="formData.preEncodedTicketSummary.error"
          v-model:isAligned="formData.preEncodedTicketSummary.isAligned"
          v-model:discrepancyReports="
            formData.preEncodedTicketSummary.discrepancyReports
          "
        />
        <afc-cash-bag-summary
          :readonly="false"
          :loading="formData.afcCashBagSummary.isLoading"
          :data="formData.afcCashBagSummary.data"
          :error="formData.afcCashBagSummary.error"
          v-model:isAligned="formData.afcCashBagSummary.isAligned"
          v-model:discrepancyReports="
            formData.afcCashBagSummary.discrepancyReports
          "
        />
        <gtm-coin-float-summary
          :readonly="false"
          :loading="formData.gtmCoinFloatSummary.isLoading"
          :data="formData.gtmCoinFloatSummary.data"
          :error="formData.gtmCoinFloatSummary.error"
          v-model:isAligned="formData.gtmCoinFloatSummary.isAligned"
          v-model:discrepancyReports="
            formData.gtmCoinFloatSummary.discrepancyReports
          "
        />
        <gtm-cash-box-summary
          :readonly="false"
          :loading="formData.gtmCashBoxSummary.isLoading"
          :data="formData.gtmCashBoxSummary.data"
          :error="formData.gtmCashBoxSummary.error"
          v-model:isAligned="formData.gtmCashBoxSummary.isAligned"
          v-model:discrepancyReports="
            formData.gtmCashBoxSummary.discrepancyReports
          "
        />
        <sccf-summary
          :readonly="false"
          :loading="formData.sccfSummary.isLoading"
          :data="formData.sccfSummary.data"
          :error="formData.sccfSummary.error"
          v-model:isAligned="formData.sccfSummary.isAligned"
          v-model:discrepancyReports="formData.sccfSummary.discrepancyReports"
        />
        <station-transfer-summary
          :readonly="false"
          :loading="formData.stationTransferSummary.isLoading"
          :data="formData.stationTransferSummary.data"
          :error="formData.stationTransferSummary.error"
          v-model:isAligned="formData.stationTransferSummary.isAligned"
          v-model:discrepancyReports="
            formData.stationTransferSummary.discrepancyReports
          "
        />
      </div>
      <div class="space-y-4">
        <h3 class="subheader">Inventory Management</h3>
        <smart-card-summary
          :readonly="false"
          :loading="formData.smartCardSummary.isLoading"
          :data="formData.smartCardSummary.data"
          :error="formData.smartCardSummary.error"
          v-model:isAligned="formData.smartCardSummary.isAligned"
          v-model:remarks="formData.smartCardSummary.remarks"
        />
      </div>
      <div class="space-y-4">
        <h3 class="subheader">Shift Handover Manual Check</h3>
        <fitness-confirmation
          :readonly="false"
          :loading="formData.fitnessConfirmation.isLoading"
          :data="formData.fitnessConfirmation.data"
          :error="formData.fitnessConfirmation.error"
          v-model:isAligned="formData.fitnessConfirmation.isAligned"
          v-model:remarks="formData.fitnessConfirmation.remarks"
        />
        <afc-key-summary
          :readonly="false"
          :loading="formData.afcKeySummary.isLoading"
          :data="formData.afcKeySummary.data"
          :error="formData.afcKeySummary.error"
          v-model:isAligned="formData.afcKeySummary.isAligned"
          v-model:remarks="formData.afcKeySummary.remarks"
        />
        <duty-sm-key-summary
          :readonly="false"
          :loading="formData.dutySMKeySummary.isLoading"
          :data="formData.dutySMKeySummary.data"
          :error="formData.dutySMKeySummary.error"
          v-model:isAligned="formData.dutySMKeySummary.isAligned"
          v-model:remarks="formData.dutySMKeySummary.remarks"
        />
        <duty-sm-item-summary
          :readonly="false"
          :loading="formData.dutySMItemSummary.isLoading"
          :data="formData.dutySMItemSummary.data"
          :error="formData.dutySMItemSummary.error"
          v-model:isAligned="formData.dutySMItemSummary.isAligned"
          v-model:remarks="formData.dutySMItemSummary.remarks"
        />
      </div>
      <div class="space-y-4">
        <h3 class="subheader">Equipment Faults</h3>
        <fault-summary
          :readonly="false"
          :loading="formData.faultSummary.isLoading"
          :data="formData.faultSummary.data"
          :error="formData.faultSummary.error"
          v-model:isAligned="formData.faultSummary.isAligned"
          v-model:remarks="formData.faultSummary.remarks"
        />
        <fault-occurrence-summary
          :readonly="false"
          :loading="formData.faultOccurrenceSummary.isLoading"
          :data="formData.faultOccurrenceSummary.data"
          :error="formData.faultOccurrenceSummary.error"
          v-model:isAligned="formData.faultOccurrenceSummary.isAligned"
          v-model:remarks="formData.faultOccurrenceSummary.remarks"
        />
      </div>
      <asom-card title="Confirmation">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            required
            label="Remarks"
            :state="inputStates('formData.remarks')"
            error="Remarks is required"
          >
            <asom-input-textarea
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
            />
          </asom-form-field>
          <div></div>
          <div>
            <asom-form-field label="Handover" disabled>
              <asom-input-text :modelValue="userName" disabled />
            </asom-form-field>
            <asom-form-field
              label="Signature (Handover)"
              error="Signature is required"
              :state="inputStates('formData.confirmation.handoverSignature')"
            >
              <asom-signature-pad
                :width="300"
                v-model="formData.confirmation.handoverSignature"
                :state="inputStates('formData.confirmation.handoverSignature')"
              />
            </asom-form-field>
          </div>
          <div>
            <asom-form-field
              v-if="isFullHandover"
              :label="'Takeover'"
              required
              :state="inputStates('formData.confirmation.takeoverUserId')"
              error="Select an officer"
            >
              <asom-input-select
                :state="inputStates('formData.confirmation.takeoverUserId')"
                v-model="formData.handoverBase.takeoverUserId"
                :loading="isLoadingOfficers"
                :options="officerOptions"
                @search-change="queryOfficerList"
                placeholder="Type to search for officer"
              />
            </asom-form-field>
          </div>
        </div>
        <div class="flex justify-end space-x-2 mt-10">
          <asom-button
            outline
            text="Back"
            variant="secondary"
            @click="onBackClicked"
          />
          <asom-button
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="Confirm Shift Handover"
            variant="primary"
            @click="onSubmitBtnClicked"
          />
        </div>
      </asom-card>
    </template>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { provide } from "vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { uploadFile } from "@/services/file.service";
import { imageDataToBlob } from "@/helpers/imageDataToBlob";
import { parseDateTime, parseDateTimeToUtc } from "@/helpers/dateTimeHelpers";
import ShiftInfo from "./components/_ShiftInfo.vue";
import PSMSummary from "./components/PSMSummary.vue";
import PSCManualChangeSummary from "./components/PSCManualChangeSummary.vue";
import PSCCashDeclarationSummary from "./components/PSCCashDeclarationSummary.vue";
import PreEncodedTicketSummary from "./components/PreEncodedTicketSummary.vue";
import AFCCashBagSummary from "./components/AFCCashBagSummary.vue";
import GTMCoinFloatSummary from "./components/GTMCoinFloatSummary.vue";
import GTMCashBoxSummary from "./components/GTMCashBoxSummary.vue";
import SCCFSummary from "./components/SCCFSummary.vue";
import StationTransferSummary from "./components/StationTransferSummary.vue";
import FitnessConfirmation from "./components/FitnessConfirmation.vue";
import SmartCardSummary from "./components/SmartCardSummary.vue";
import AFCKeySummary from "./components/AFCKeySummary.vue";
import DutySMKeySummary from "./components/DutySMKeySummary.vue";
import DutySMItemSummary from "./components/DutySMItemSummary.vue";
import FaultSummary from "./components/FaultSummary.vue";
import FaultOccurrenceSummary from "./components/FaultOccurrenceSummary.vue";
import {
  getLastHandover,
  getPSMSummary,
  getManualChangeSummary,
  getPreEncodedSummary,
  getAFCCashBagELogbook,
  getGTMCoinFloatELogbook,
  getGTMCashBoxELogbook,
  getSCCFSummary,
  getStationTransferSummary,
  getStationOccurrenceSummary,
  getStationOutstandingFaultSummary,
  getSmartCardInventorySummary,
  getSMDutyItemList,
  getSMKeyList,
  createShiftHandoverReport,
  getPscCashDeclarationSummary,
} from "../../services/shiftHandover.service";
import { getNextRoster } from "@/services/manpower.service";
import { getAsync } from "../../services/serviceHandlers";
import {
  GET_AFC_STORE_SPARE_KEY_DETAILS,
  GET_HEALTH_DECLARATION,
} from "../../constants/apis";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";
import { listUsers } from "../../services/user.service";
import { HandoverTypes } from "@/constants/APIEnums/shiftHandoverEnums";
import { getShiftHandoverValidationErrors } from "./utils/validationHelpers";
import ROLES from '../../../Roles.json'

function getHandoverType(route) {
  const path = get(route, "path", "");
  if (path.includes("full-handover")) return HandoverTypes.FULL_HANDOVER.VALUE;
  if (path.includes("soft-handover")) return HandoverTypes.SOFT_HANDOVER.VALUE;
  return HandoverTypes.SOFT_TAKEOVER.VALUE;
}

export default {
  components: {
    ShiftInfo,
    "psm-summary": PSMSummary,
    "psc-manual-change-summary": PSCManualChangeSummary,
    PreEncodedTicketSummary,
    "afc-cash-bag-summary": AFCCashBagSummary,
    "gtm-coin-float-summary": GTMCoinFloatSummary,
    "gtm-cash-box-summary": GTMCashBoxSummary,
    "sccf-summary": SCCFSummary,
    StationTransferSummary,
    SmartCardSummary,
    FitnessConfirmation,
    "afc-key-summary": AFCKeySummary,
    "duty-sm-key-summary": DutySMKeySummary,
    "duty-sm-item-summary": DutySMItemSummary,
    FaultSummary,
    FaultOccurrenceSummary,
    "psc-cash-declaration-summary": PSCCashDeclarationSummary,
  },
  mixins: [inputStates],
  setup() {
    const v$ = useVuelidate();
    provide("v$", v$);
    return {
      v$,
    };
  },
  data() {
    return {
      shiftInfoError: null,
      isLoadingShiftInfo: false,
      submissionError: null,
      isSubmitting: false,
      formData: {
        handoverBase: {
          type: HandoverTypes.FULL_HANDOVER.VALUE,
          startTime: null,
          endTime: new Date(),
          takeoverUserName: null,
          takeoverUserId: null,
          takeoverRosterDWS: "",
        },
        confirmation: {
          handoverSignature: null,
          handoverSignatureId: null,
        },
        // cash management
        psmSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        pscManualChangeSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        pscCashDeclarationSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        preEncodedTicketSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        afcCashBagSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        gtmCoinFloatSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        gtmCashBoxSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        sccfSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        stationTransferSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          discrepancyReports: [],
        },
        // inventory
        smartCardSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          remarks: "",
        },
        // shift handover
        fitnessConfirmation: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          remarks: "",
        },
        afcKeySummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          remarks: "",
        },
        dutySMKeySummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          remarks: "",
        },
        dutySMItemSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          remarks: "",
        },
        // equipment fault
        faultSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          remarks: "",
        },
        faultOccurrenceSummary: {
          isLoading: false,
          error: null,
          data: null,
          isAligned: true,
          remarks: "",
        },
        remarks: null,
      },
      officerOptions: [],
      isLoadingOfficers: false,
    };
  },
  validations() {
    let rules = {
      formData: {
        handoverBase: {
          endTime: { required },
        },
        confirmation: {
          handoverSignature: { required },
        },
        psmSummary: {
          data: { required },
        },
        pscManualChangeSummary: {
          data: { required },
        },
        pscCashDeclarationSummary: {
          data: { required },
        },
        preEncodedTicketSummary: {
          data: { required },
        },
        afcCashBagSummary: {
          data: { required },
        },
        gtmCoinFloatSummary: {
          data: { required },
        },
        gtmCashBoxSummary: {
          data: { required },
        },
        sccfSummary: {
          data: { required },
        },
        stationTransferSummary: {
          data: { required },
        },
        smartCardSummary: {
          data: { required },
        },
        fitnessConfirmation: {},
        afcKeySummary: {},
        dutySMKeySummary: {
          data: { required },
        },
        dutySMItemSummary: {
          data: { required },
        },
        faultSummary: {},
        faultOccurrenceSummary: {},
        remarks: { required },
      },
    };

    if (
      this.formData.handoverBase.type.toString() ===
      HandoverTypes.FULL_HANDOVER.VALUE.toString()
    ) {
      rules.formData.handoverBase.takeoverUserId = { required };
      // rules.formData.handoverBase.takeoverRosterDWS = { required };
    }
    if (!this.formData.psmSummary.isAligned) {
      rules.formData.psmSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.pscManualChangeSummary.isAligned) {
      rules.formData.pscManualChangeSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.pscCashDeclarationSummary.isAligned) {
      rules.formData.pscCashDeclarationSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.preEncodedTicketSummary.isAligned) {
      rules.formData.preEncodedTicketSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.afcCashBagSummary.isAligned) {
      rules.formData.afcCashBagSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.gtmCoinFloatSummary.isAligned) {
      rules.formData.gtmCoinFloatSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.gtmCashBoxSummary.isAligned) {
      rules.formData.gtmCashBoxSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.sccfSummary.isAligned) {
      rules.formData.sccfSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.stationTransferSummary.isAligned) {
      rules.formData.stationTransferSummary.discrepancyReports = {
        required,
        minLength: minLength(1),
      };
    }
    if (!this.formData.smartCardSummary.isAligned) {
      rules.formData.smartCardSummary.remarks = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      };
    }
    if (!this.formData.fitnessConfirmation.isAligned) {
      rules.formData.fitnessConfirmation.remarks = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      };
    }
    if (!this.formData.afcKeySummary.isAligned) {
      rules.formData.afcKeySummary.remarks = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      };
    }
    if (!this.formData.dutySMKeySummary.isAligned) {
      rules.formData.dutySMKeySummary.remarks = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      };
    }
    if (!this.formData.dutySMItemSummary.isAligned) {
      rules.formData.dutySMItemSummary.remarks = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      };
    }
    if (!this.formData.faultSummary.isAligned) {
      rules.formData.faultSummary.remarks = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      };
    }
    if (!this.formData.faultOccurrenceSummary.isAligned) {
      rules.formData.faultOccurrenceSummary.remarks = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      };
    }
    return rules;
  },
  computed: {
    ...mapGetters({
      userId: "auth/userId",
      lineId: "auth/userLineId",
      userName: "auth/userName",
      rosterDWS: "currentRoster/currentRosterShift",
      //rosterStationId: "currentRoster/currentStationId",
      // rosterStationId: "auth/userStationId",
      //rosterStationName: "currentRoster/currentStationName",
      rosterStationId: "selectedStation/id",
      rosterStationName: "selectedStation/name"
    }),
    isFullHandover() {
      return (
        this.formData.handoverBase.type === HandoverTypes.FULL_HANDOVER.VALUE
      );
    },
    requestParams() {
      return {
        stationId: this.rosterStationId,
        lineId: this.lineId,
        startDate: parseDateTime(this.formData.handoverBase.startTime),
        endDate: parseDateTime(this.formData.handoverBase.endTime),
      }
    },
    requestParamsInUtc(){
      return {
        stationId: this.requestParams.stationId,
        lineId: this.requestParams.lineId,
        startDate: parseDateTimeToUtc(this.formData.handoverBase.startTime),
        endDate: parseDateTimeToUtc(this.formData.handoverBase.endTime),
      }
    }
  },
  mounted() {
    if (!this.rosterStationId) this.shiftInfoError = "No Roster found";

    this.formData.handoverBase.type = getHandoverType(this.$route);

    if (
      this.rosterDWS !== null &&
      this.rosterStationId !== null &&
      this.rosterStationName !== null
    ) {
      this.loadShiftData();
      this.getNextRosterInfo();
    }
  },
  methods: {
    get,
    async queryOfficerList(query) {
      this.isLoadingOfficers = true;
      if (!query) {
        this.isLoadingOfficers = false;
        this.officerOptions = [];
      }
      const result = await listUsers({
        search: query.trim(),
        lineId: this.lineId,
        roleNames: [
          ROLES.AssistantStationManager,
          ROLES.SeniorAssistantStationManager,
          ROLES.StationManager,
          ROLES.SeniorStationManager,
        ]
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingOfficers = false;
        this.error = null;
        this.officerOptions = get(result, "payload.list")
          .filter(({ id }) => id !== this.userId)
          .map(
            ({ id, name, personNo }) => ({
              label: `${name} (${personNo})`,
              value: id,
            })
          );
      } else {
        this.isLoadingOfficers = false;
        this.error = result.payload;
        this.officerOptions = [];
      }
    },
    async getNextRosterInfo() {
      const resp = await getNextRoster({
        stationId: this.rosterStationId,
        officerId: this.userId,
        planDate: parseDateTime(this.formData.handoverBase.endTime).split(
          "T"
        )[0],
      });
      if (resp.success && get(resp.payload, "roster", null) !== null) {
        this.formData.handoverBase.takeoverRosterDWS = get(
          resp,
          "payload.roster.dws",
          null
        );
        this.formData.handoverBase.takeoverUserName = get(
          resp,
          "payload.roster.officer",
          null
        );
        this.formData.handoverBase.takeoverUserId = {
          value: get(resp, "payload.roster.officerId"),
          label: get(resp, "payload.roster.officer"),
        };
      } else {
        if (get(resp.payload, "roster", null) == null) {
          this.submissionError = "No Roster Available";
        } else this.submissionError = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadShiftData() {
      const loadPrevShiftSucceeded = await this.loadPrevShiftData();
      if (!loadPrevShiftSucceeded) {
        return;
      }
      this.loadPSMSummary();
      this.loadPSCManualChangeSummary();
      this.loadPreEncodedTicketSummary();
      this.loadAFCCashBagSummary();
      this.loadGTMCoinFloatSummary();
      this.loadGTMCashBagSummary();
      this.loadSCCFSummary();
      this.loadStationTransferSummary();
      this.loadSmartCardSummary();
      this.loadAFCKeySummary();
      this.loadDutySMKeySummary();
      this.loadDutySMItemSummary();
      this.loadFitnessConfirmation();
      this.loadFaultSummary();
      this.loadFaultOccurrenceSummary();
      this.loadPSCCashDeclarationSummary();
    },
    async loadPrevShiftData() {
      let loadSucceeded = false;
      this.isLoadingShiftInfo = true;
      const resp = await getLastHandover(this.requestParams);
      if (resp.success) {
        let date = get(
          resp.payload,
          "lastShiftHandoverDate",
          moment().subtract(6, "day").toDate()
        );
        if (date !== null) this.formData.handoverBase.startTime = date;
        loadSucceeded = true;
        this.isLoadingShiftInfo = false;
        this.shiftInfoError = null;
      } else {
        this.isLoadingShiftInfo = false;
        this.shiftInfoError = resp.payload;
        this.$scrollTop();
        return;
      }
      return loadSucceeded;
    },
    async loadPSMSummary() {
      this.formData.psmSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getPSMSummary(params);
      if (resp.success) {
        this.formData.psmSummary.data = get(resp.payload, "data", {});
        this.formData.psmSummary.isLoading = false;
        this.formData.psmSummary.error = null;
      } else {
        this.formData.psmSummary.isLoading = false;
        this.formData.psmSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadPSCManualChangeSummary() {
      this.formData.pscManualChangeSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getManualChangeSummary(params);
      if (resp.success) {
        this.formData.pscManualChangeSummary.data = get(
          resp.payload,
          "data",
          {}
        );
        this.formData.pscManualChangeSummary.isLoading = false;
        this.formData.pscManualChangeSummary.error = null;
      } else {
        this.formData.pscManualChangeSummary.isLoading = false;
        this.formData.pscManualChangeSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadPSCCashDeclarationSummary() {
      this.formData.pscCashDeclarationSummary.isLoading = true;
      let params = this.requestParamsInUtc;
      try {
        params = JSON.parse(JSON.stringify(this.requestParamsInUtc));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getPscCashDeclarationSummary(params);
      if (resp.success) {
        this.formData.pscCashDeclarationSummary.data = get(
          resp.payload,
          "data",
          {}
        );
        this.formData.pscCashDeclarationSummary.isLoading = false;
        this.formData.pscCashDeclarationSummary.error = null;
      } else {
        this.formData.pscCashDeclarationSummary.isLoading = false;
        this.formData.pscCashDeclarationSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadPreEncodedTicketSummary() {
      this.formData.preEncodedTicketSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getPreEncodedSummary(params);
      if (resp.success) {
        this.formData.preEncodedTicketSummary.data = get(
          resp.payload,
          "data",
          {}
        );
        this.formData.preEncodedTicketSummary.isLoading = false;
      } else {
        this.formData.pscManualChangeSummary.isLoading = false;
        this.formData.pscManualChangeSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadAFCCashBagSummary() {
      this.formData.afcCashBagSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        let endDate = moment(params.endDate);
        if (endDate.isValid()){
          params.startDate = parseDateTime(endDate.subtract(2, "day").toDate());
        }
      }
      catch (error) {
        console.error(error);
      }
      const resp = await getAFCCashBagELogbook(params);
      if (resp.success) {
        this.formData.afcCashBagSummary.data = get(resp.payload, "data", {});
        this.formData.afcCashBagSummary.isLoading = false;
        this.formData.afcCashBagSummary.error = null;
      } else {
        this.formData.afcCashBagSummary.isLoading = false;
        this.formData.afcCashBagSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadGTMCoinFloatSummary() {
      this.formData.gtmCoinFloatSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getGTMCoinFloatELogbook(params);
      if (resp.success) {
        this.formData.gtmCoinFloatSummary.data = get(resp.payload, "data", {});
        this.formData.gtmCoinFloatSummary.isLoading = false;
        this.formData.gtmCoinFloatSummary.error = null;
      } else {
        this.formData.gtmCoinFloatSummary.isLoading = false;
        this.formData.gtmCoinFloatSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadGTMCashBagSummary() {
      this.formData.gtmCashBoxSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getGTMCashBoxELogbook(params);
      if (resp.success) {
        this.formData.gtmCashBoxSummary.data = get(resp.payload, "data", {});
        this.formData.gtmCashBoxSummary.isLoading = false;
        this.formData.gtmCashBoxSummary.error = null;
      } else {
        this.formData.gtmCashBoxSummary.isLoading = false;
        this.formData.gtmCashBoxSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadSCCFSummary() {
      this.formData.sccfSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getSCCFSummary(params);
      if (resp.success) {
        this.formData.sccfSummary.data = get(resp.payload, "data", []);
        this.formData.sccfSummary.isLoading = false;
        this.formData.sccfSummary.error = null;
      } else {
        this.formData.sccfSummary.isLoading = false;
        this.formData.sccfSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadStationTransferSummary() {
      this.formData.stationTransferSummary.isLoading = true;
      let params = this.requestParamsInUtc;
      try {
        params = JSON.parse(JSON.stringify(this.requestParamsInUtc));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getStationTransferSummary(params);
      if (resp.success) {
        this.formData.stationTransferSummary.data = get(
          resp.payload,
          "data",
          {}
        );
        this.formData.stationTransferSummary.isLoading = false;
        this.formData.stationTransferSummary.error = null;
      } else {
        this.formData.stationTransferSummary.isLoading = false;
        this.formData.stationTransferSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadSmartCardSummary() {
      this.formData.smartCardSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getSmartCardInventorySummary(params);
      if (resp.success) {
        this.formData.smartCardSummary.data = get(resp, "payload", {});
        this.formData.smartCardSummary.isLoading = false;
        this.formData.smartCardSummary.error = null;
      } else {
        this.formData.smartCardSummary.isLoading = false;
        this.formData.smartCardSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadAFCKeySummary() {
      this.formData.afcKeySummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getAsync({
        url: GET_AFC_STORE_SPARE_KEY_DETAILS,
        params: params,
      });
      if (resp.success) {
        this.formData.afcKeySummary.data = get(resp, "payload.data", "");
        this.formData.afcKeySummary.isLoading = false;
        this.formData.afcKeySummary.error = null;
      } else {
        this.formData.afcKeySummary.isLoading = false;
        this.formData.afcKeySummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadDutySMKeySummary() {
      this.formData.dutySMKeySummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getSMKeyList(params);
      if (resp.success) {
        this.formData.dutySMKeySummary.data = get(resp, "payload", {});
        this.formData.dutySMKeySummary.isLoading = false;
        this.formData.dutySMKeySummary.error = null;
      } else {
        this.formData.dutySMKeySummary.isLoading = false;
        this.formData.dutySMKeySummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadDutySMItemSummary() {
      this.formData.dutySMItemSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getSMDutyItemList(params);
      if (resp.success) {
        this.formData.dutySMItemSummary.data = get(resp, "payload", {});
        this.formData.dutySMItemSummary.isLoading = false;
        this.formData.dutySMItemSummary.error = null;
      } else {
        this.formData.dutySMItemSummary.isLoading = false;
        this.formData.dutySMItemSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadFitnessConfirmation() {
      this.formData.fitnessConfirmation.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getAsync({
        url: GET_HEALTH_DECLARATION,
        params: params,
      });
      if (resp.success) {
        this.formData.fitnessConfirmation.data = get(resp.payload, "data", {});
        this.formData.fitnessConfirmation.isLoading = false;
        this.formData.fitnessConfirmation.error = null;
      } else {
        this.formData.fitnessConfirmation.isLoading = false;
        this.formData.fitnessConfirmation.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadFaultSummary() {
      this.formData.faultSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getStationOutstandingFaultSummary(params);
      if (resp.success) {
        this.formData.faultSummary.data = get(resp.payload, "data", {});
        this.formData.faultSummary.isLoading = false;
        this.formData.faultSummary.error = null;
      } else {
        this.formData.faultSummary.isLoading = false;
        this.formData.faultSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    async loadFaultOccurrenceSummary() {
      this.formData.faultOccurrenceSummary.isLoading = true;
      let params = this.requestParams;
      try {
        params = JSON.parse(JSON.stringify(this.requestParams));
        if (params.startDate === null || params.startDate === ''){
          params.startDate = parseDateTime(new Date(0));
        }
      }
      catch (error) {
        console.error("ERROR: ", error);
      }
      const resp = await getStationOccurrenceSummary(params);
      if (resp.success) {
        this.formData.faultOccurrenceSummary.data = get(
          resp.payload,
          "data",
          {}
        );
        this.formData.faultOccurrenceSummary.isLoading = false;
        this.formData.faultOccurrenceSummary.error = null;
      } else {
        this.formData.faultOccurrenceSummary.isLoading = false;
        this.formData.faultOccurrenceSummary.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    onBackClicked() {
      this.$router.go(-1);
    },
    onSubmitBtnClicked() {
      if (this.isSubmitting) return;
      this.submissionError = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (!this.v$.formData.$invalid) {
        this.onSubmit();
      } else {
        this.submissionError = getShiftHandoverValidationErrors(this.v$);
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const isSignatureUploaded =
        this.formData.confirmation.handoverSignature !== null &&
        this.formData.confirmation.handoverSignatureId !== null;
      if (!isSignatureUploaded) {
        const signatureBlob = imageDataToBlob(
          this.formData.confirmation.handoverSignature,
          "handover_signature"
        );
        const uploadResult = await uploadFile([signatureBlob], ATTACHMENT_CATEGORIES.SHIFT_HANDOVER);
        if (!uploadResult.success) {
          this.submissionError = uploadResult.payload;
          this.isSubmitting = false;
          this.$scrollTop();
          return;
        }
        this.formData.confirmation.handoverSignatureId = get(
          uploadResult,
          "payload.files.0.fileId"
        );
      }
      const takeoverUserId = (this.formData.handoverBase.type.toString() ===
      HandoverTypes.SOFT_HANDOVER.VALUE.toString()) ?
        null :
        get(this.formData.handoverBase.takeoverUserId, "value");
      var shiftStartDate = parseDateTime(this.formData.handoverBase.startTime);
      if (shiftStartDate === null || shiftStartDate === ''){
        shiftStartDate = parseDateTime(new Date(0));
      }
      let submissionData = {
        stationId: this.rosterStationId,
        lineId: this.lineId,
        handoverRosterDWS: this.rosterDWS,
        takeoverRosterDWS: this.formData.handoverBase.takeoverRosterDWS,
        startDate: shiftStartDate,
        endDate: parseDateTime(this.formData.handoverBase.endTime),
        type: this.formData.handoverBase.type,
        takeoverUserId: takeoverUserId,
        handoverSignatureId: this.formData.confirmation.handoverSignatureId,
        psmSummary: this.formData.psmSummary.data,
        psmSummaryDiscrepancyReportIds: this.formData.psmSummary.isAligned
          ? []
          : this.formData.psmSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),
        pscManualChangeSummary: this.formData.pscManualChangeSummary.data,
        pscManualChangeDiscrepancyReportIds: this.formData
          .pscManualChangeSummary.isAligned
          ? []
          : this.formData.pscManualChangeSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),
        pscCashDeclarationSummary: this.formData.pscCashDeclarationSummary.data,
        pscCashDeclarationDiscrepancyReportIds: this.formData
          .pscCashDeclarationSummary.isAligned
          ? []
          : this.formData.pscCashDeclarationSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),
        preEncodedTicketSummary: this.formData.preEncodedTicketSummary.data,
        preEncodedTicketDiscrepancyReportIds: this.formData
          .preEncodedTicketSummary.isAligned
          ? []
          : this.formData.preEncodedTicketSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),
        afcCashBagSummary: this.formData.afcCashBagSummary.data,
        afcCashBagDiscrepancyReportIds: this.formData.afcCashBagSummary
          .isAligned
          ? []
          : this.formData.afcCashBagSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),
        gtmCoinFloatSummary: this.formData.gtmCoinFloatSummary.data,
        gtmCoinFloatDiscrepancyReportIds: this.formData.gtmCoinFloatSummary
          .isAligned
          ? []
          : this.formData.gtmCoinFloatSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),
        gtmCashBoxSummary: this.formData.gtmCashBoxSummary.data,
        gtmCashBoxDiscrepancyReportIds: this.formData.gtmCashBoxSummary
          .isAligned
          ? []
          : this.formData.gtmCashBoxSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),
        sccfSummary: this.formData.sccfSummary.data,
        sccfDiscrepancyReportIds: this.formData.sccfSummary.isAligned
          ? []
          : this.formData.sccfSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),
        stationTransferSummary: this.formData.stationTransferSummary.data,
        stationTransferDiscrepancyReportIds: this.formData
          .stationTransferSummary.isAligned
          ? []
          : this.formData.stationTransferSummary.discrepancyReports.map(
              (r) => r.discrepancyReportId
            ),

        smartCardSummary: get(
          this.formData.smartCardSummary.data,
          "data",
          null
        ),
        smartCardRemarks: this.formData.smartCardSummary.isAligned
          ? ""
          : this.formData.smartCardSummary.remarks,

        fitnessConfirmation: {
          data: this.formData.fitnessConfirmation.data,
          remarks: this.formData.fitnessConfirmation.isAligned
            ? ""
            : this.formData.fitnessConfirmation.remarks,
        },

        afcStoreRoomKeyConfirmation: {
          data: this.formData.afcKeySummary.data,
          remarks: this.formData.afcKeySummary.isAligned
            ? ""
            : this.formData.afcKeySummary.remarks,
        },

        smDutyKeyConfirmation: {
          keys: get(this.formData.dutySMKeySummary.data, "list", []).map(
            (key) => {
              return key.name;
            }
          ),
          remarks: this.formData.dutySMKeySummary.isAligned
            ? ""
            : this.formData.dutySMKeySummary.remarks,
        },

        smDutyPhoneConfirmation: {
          items: get(this.formData.dutySMItemSummary.data, "list", []).map(
            (key) => {
              return key.name;
            }
          ),
          remarks: this.formData.dutySMItemSummary.isAligned
            ? ""
            : this.formData.dutySMItemSummary.remarks,
        },

        stationFaultSummary: this.formData.faultSummary.data,
        stationFaultRemarks: this.formData.faultSummary.isAligned
          ? ""
          : this.formData.faultSummary.remarks,
        stationOccurrenceSummary: this.formData.faultOccurrenceSummary.data,
        stationOccurrenceRemarks: this.formData.faultOccurrenceSummary.isAligned
          ? ""
          : this.formData.faultOccurrenceSummary.remarks,
        remarks: this.formData.remarks,
      };

      const result = await createShiftHandoverReport(submissionData);
      if (result.success) {
        this.$router.go(-1);
      } else {
        this.submissionError = result.payload;
        this.$scrollTop();
      }
      this.isSubmitting = false;
    },
  },
};
</script>
